import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  USER_HAPPIER_LEADS,
  USER_DETAILS_HAPPIER_LEADS,
  SIGNIN_GOOGLE_USER,
  SEND_RECOVERY_EMAIL,
  SUCCESS_RECOVERY_EMAIL,
  SEND_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  MOVE_USER_TO_HOME,
  WEBSITE_CLIENT_ID,
  RESET_VERIFIED_WEBSITE,
  SET_WEBSITEURL_SETUPSCRIPT,
  CHECK_TRIAL,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_SUCCESS,
  DELETE_MY_ACCOUNT,
  DELETE_MY_ACCOUNT_SUCCESS,
  DELETE_MY_ACCOUNT_ERROR,
} from '../../constants/ActionTypes';

function dateDifference(date2, date1) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: null,
  userHappierLeads: {},
  firstNameUser: '',
  lastNameUser: '',
  responseGoogleToken: '',
  sentResetEmail: false,
  resetEmail: '',
  resetInfo: {},
  installClientId: '',
  firstWebsiteUrl: '',
  expiredTrial: false,
  refreshOn: null,
  verifyEmailSuccess: false,
  verifyEmailLoading: false,
  verifyEmailError: false,
  deleteAccountId: '',
  deleteAccountError: false,
  deleteAccountSuccess: false
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case VERIFY_EMAIL_REQUEST: {
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailSuccess: false,
      };
    }

    case VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailSuccess: true,
      };
    }
    case VERIFY_EMAIL_ERROR: {
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailSuccess: false,
        verifyEmailError: false,
      };
    }
    case SIGNIN_GOOGLE_USER: {
      return {
        ...state,
        loader: true,
        responseGoogleToken: action.payload.tokenId,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/websites',
        loader: false,
        userHappierLeads: {},
        firstNameUser: '',
        lastNameUser: '',
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case USER_HAPPIER_LEADS: {
      let today = new Date();

      let newRefreshOn = null;
      if (action.payload.refreshOn) {
        let refreshDate = new Date(action.payload.refreshOn);
        newRefreshOn = dateDifference(refreshDate, today);
      }
      return {
        ...state,
        userHappierLeads: action.payload,
        firstNameUser: action.payload.user.firstName,
        lastNameUser: action.payload.user.lastName,
        refreshOn: newRefreshOn,
      };
    }
    case USER_DETAILS_HAPPIER_LEADS: {
      return {
        ...state,
        loader: false,
      };
    }

    case SUCCESS_RECOVERY_EMAIL: {
      return {
        ...state,
        sentResetEmail: true,
        loader: false,
      };
    }

    case SEND_RECOVERY_EMAIL: {
      return {
        ...state,
        loader: true,
        resetEmail: action.payload.email,
      };
    }

    case SEND_RESET_PASSWORD: {
      return {
        ...state,
        loader: true,
        resetInfo: action.payload,
      };
    }

    case SUCCESS_RESET_PASSWORD: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }

    case MOVE_USER_TO_HOME: {
      return {
        ...state,
      };
    }

    case WEBSITE_CLIENT_ID: {
      return {
        ...state,
        installClientId: action.payload,
      };
    }

    case RESET_VERIFIED_WEBSITE: {
      state.userHappierLeads.websites.forEach((filter) => {
        if (filter.clientId === action.payload) {
          filter.verified = false;
        }
      });

      return {
        ...state,
      };
    }

    case SET_WEBSITEURL_SETUPSCRIPT: {
      return {
        ...state,
        firstWebsiteUrl: action.payload,
      };
    }

    case CHECK_TRIAL: {
      let checkTrialStatus = false;
      const dateInPast = function (firstDate, secondDate) {
        if (firstDate < secondDate) {
          return true;
        }

        return false;
      };

      if (state.userHappierLeads.plan) {
        if (state.userHappierLeads.plan === 'Trial') {
          let past = new Date(state.userHappierLeads.trialEnds);
          let today = new Date();
          checkTrialStatus = dateInPast(past, today);
        } else {
          checkTrialStatus = false;
        }
      }

      return {
        ...state,
        expiredTrial: checkTrialStatus,
      };
    }

    case DELETE_MY_ACCOUNT: {
      return {
        ...state,
        deleteAccountId: action.payload,
        deleteAccountLoading: true
      };
    }
    case DELETE_MY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deleteAccountId: action.payload,
        deleteAccountLoading: false,
        deleteAccountSuccess: true,
        successMsg: action.payload
      };
    }
    case DELETE_MY_ACCOUNT_ERROR: {
      return {
        ...state,
        deleteAccountId: action.payload,
        deleteAccountLoading: false,
        deleteAccountError: true
      };
    }

    default:
      return state;
  }
};

export default Auth;
