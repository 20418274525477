import React, { useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useSelector, useDispatch } from 'react-redux';

import '../routes/session-player/styles/index.scss';
import '../public/vendors/flag/sprite-flags-24x24.css';
import '../public/vendors/material-design-iconic-font/css/material-design-iconic-font.min.css';
import '../public/vendors/animate.css';
import '../public/vendors/bootstrap-rtl.css';
import 'react-notifications/lib/notifications.css';
import '../styles/bootstrap.scss';
import '../styles/app.scss';
import '../styles/app-rtl.scss';
import 'rc-slider/assets/index.css';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-intl-tel-input/dist/main.css';
import { wrapper } from '../redux/store/index';
import 'setimmediate';
import GeneralMessage from '../app/components/GeneralMessage';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { getSettings } from '../utils/session';




const App = ({ Component, pageProps }) => {
  const dispatch = useDispatch();
  const { userHappierLeads } = useSelector(({ auth }) => auth);
  const [settings, setSettings] = useState({});

//   const intl = useIntl();
// const appName = intl.formatMessage({ id: 'app.name' });
// const appDomain = intl.formatMessage({ id: 'app.domain' });



  useEffect(() => {
    if(typeof window != 'undefined'){
       setSettings(getSettings())
    }
  }, []);
  // INTEGRATIONS SCRIPT LOAD
  const router = useRouter();

  useEffect(() => {
    let found;
    if (Object.keys(userHappierLeads).length > 0) {
      const websites = userHappierLeads?.websites;
      const workspace = router.query.workspace;

      found = websites?.some((obj) => obj._id === workspace);
    }
    const allowedPages = [
      'billing',
      'upgrade',
      'setup-tracking-script',
      'affiliate',
      'verify-email',
      'integration-connected',
    ];
    if (!found && Object.keys(userHappierLeads).length > 0 && !allowedPages?.includes(router.asPath?.split('/')[1])) {
      if ('websites' in userHappierLeads && userHappierLeads.websites.length > 0) {
        const currentWebsiteId = localStorage.getItem('currentWebsiteId');
        const currentWebsiteUrl = localStorage.getItem('currentWebsiteUrl');
        const newWebId = userHappierLeads?.defaultWorkspace ?? userHappierLeads?.websites[0]?._id;
        if (!currentWebsiteUrl) {
          if (userHappierLeads.websites) {
            localStorage.setItem('currentWebsiteUrl', userHappierLeads.websites[0].validationUrl);
            localStorage.setItem('currentWebsiteId', newWebId);
          }
        }

        if (currentWebsiteId || userHappierLeads.websites) {
          const useWebsiteId = currentWebsiteId ? currentWebsiteId : newWebId;
          const url = router?.asPath;
          const newUrl = url?.replace(/\/([^/]+)\/([^/?]+)/, `/$1/${useWebsiteId}`);
          router.push(newUrl);
          typeof window !== 'undefined' && localStorage.setItem('currentWebsiteId', useWebsiteId);
        }
      }
    }
  }, [dispatch, userHappierLeads, userHappierLeads.websites, router.query.workspace]);

  useEffect(() => {
    if (userHappierLeads.websites) {
      const currentWebsiteUrl = localStorage.getItem('currentWebsiteUrl');
      if (router.query.workspace) {
        const currentWebsite = userHappierLeads.websites.filter((website) => website._id === router.query.workspace);
        if (currentWebsite.length > 0) {
          localStorage.setItem('currentWebsiteUrl', currentWebsite[0].validationUrl);
          localStorage.setItem('currentWebsiteId', currentWebsite[0]._id);
        }
      } else {
        if (!currentWebsiteUrl) {
          if (userHappierLeads.websites) {
            localStorage.setItem('currentWebsiteUrl', userHappierLeads.websites[0].validationUrl);
            localStorage.setItem('currentWebsiteId', userHappierLeads.websites[0]._id);
          }
        }
      }
    }
  }, [userHappierLeads.websites]);
  /* INTEGRATIONS SCRIPT LOAD END */

  return (
    <React.Fragment>
      {settings['app.domain'] && (
        <>
          <Script
            id={settings['app.domain']}
            strategy="afterInteractive"
            src={settings['app.domain'] == 'happierleads.com' ? '/scripts/happierleadsScript.js' : '/scripts/otherScript.js'}
          />     
        </>
      )}
      {settings['app.domain'] == 'happierleads.com' && (
        <>         
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-MCBSPR5"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
          <Script src="https://www.googleoptimize.com/optimize.js?id=OPT-W9FJC8R" async />
        </>
      )}

      

      {/* BOOTSTRAP. POPPERJS */}
      <Script
        strategy="beforeInteractive"
        src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossOrigin="anonymous"
      />
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
        strategy="beforeInteractive"
      />

      {/* <Script src="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places,geometry,drawing&key=AIzaSyA72EHVeUE3qZ4eG6BnHgxgfIWH48dTEDA" /> */}
      <Head>
        <title> {settings['app.name']} Admin Dashboard</title>
        <link
            rel="shortcut icon"
            href={settings['app.logo.image']}
          />
      </Head>
      <GeneralMessage />
      <ToastContainer />
      <Component {...pageProps} />
    </React.Fragment>
  );
};

export default wrapper.withRedux(App);
