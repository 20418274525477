import {
  ADD_NEW_WEBSITES_REQUEST,
  ADD_NEW_WEBSITE_ERROR,
  ADD_NEW_WEBSITE_SUCCESS,
  CLEAR_MESSAGES_WEBSITES,
  DELETE_WEBSITE_REQUEST,
  WEBSITES_UPDATE_REQUEST,
  WEBSITES_UPDATE_GDPRSUPPRESSION,
  WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY,
  SET_WEBSITE_SETTINGS,
  ADD_BLACKLIST_WEBSITE,
  FETCH_BLACKLIST_WEBSITES,
  FETCH_BLACKLIST_WEBSITES_SUCCESS,
  SHOW_WORKSPACE_MODAL,
  FETCH_BLACKLIST_WEBSITES_ERROR,
  RENAME_WORKSPACE_REQUEST,
  RENAME_WORKSPACE_ERROR,
  RENAME_WORKSPACE_SUCCESS,
  RENAME_WORKSPACE_MODAL,
  ADD_NEW_EXCLUDED_PAGES_REQUEST,
  ADD_NEW_EXCLUDED_PAGES_SUCCESS,
  ADD_NEW_EXCLUDED_PAGES_ERROR,
  ADD_FAVORITE_WEBSITE_REQUEST,
  ADD_FAVORITE_WEBSITE_SUCCESS,
  ADD_FAVORITE_WEBSITE_ERROR,
  REMOVE_FAVORITE_WEBSITE_REQUEST,
  REMOVE_FAVORITE_WEBSITE_SUCCESS,
  REMOVE_FAVORITE_WEBSITE_ERROR,
  REDEEM_CODE_REQUEST,
  REDEEM_CODE_SUCCESS,
  REDEEM_CODE_ERROR,
  WEBSITES_UPDATE_ERROR,
  WEBSITES_UPDATE_SUCCESS,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_ERROR,
} from '../../constants/ActionTypes';

export const AddNewWebsiteRequest = (name) => {
  return {
    type: ADD_NEW_WEBSITES_REQUEST,
    payload: name,
  };
};

export const AddNewWebsiteSuccess = (message) => {
  return {
    type: ADD_NEW_WEBSITE_SUCCESS,
    payload: message,
  };
};

export const AddNewWebsiteError = () => {
  return {
    type: ADD_NEW_WEBSITE_ERROR,
  };
};

export const AddFavoriteWebsite = (data) => {
  return {
    type: ADD_FAVORITE_WEBSITE_REQUEST,
    payload: data,
  };
};

export const AddFavoriteWebsiteSuccess = (message) => {
  return {
    type: ADD_FAVORITE_WEBSITE_SUCCESS,
    payload: message,
  };
};

export const AddFavoriteWebsiteError = () => {
  return {
    type: ADD_FAVORITE_WEBSITE_ERROR,
  };
};
export const removeFavoriteWebsite = (data) => {
  return {
    type: REMOVE_FAVORITE_WEBSITE_REQUEST,
    payload: data,
  };
};

export const removeFavoriteWebsiteSuccess = (message) => {
  return {
    type: REMOVE_FAVORITE_WEBSITE_SUCCESS,
    payload: message,
  };
};

export const removeFavoriteWebsiteError = () => {
  return {
    type: REMOVE_FAVORITE_WEBSITE_ERROR,
  };
};

export const clearMessagesWebsites = () => {
  return {
    type: CLEAR_MESSAGES_WEBSITES,
  };
};

export const setNewWorkspaceModal = (mode) => {
  return {
    type: SHOW_WORKSPACE_MODAL,
    payload: mode,
  };
};

export const deleteWebsite = (id) => {
  return {
    type: DELETE_WEBSITE_REQUEST,
    payload: id,
  };
};

export const deleteWebsiteSuccess = (data) => {  
  return {
    type: DELETE_WEBSITE_SUCCESS,
    payload: data,
  };
};

export const deleteWebsiteError = (message) => {
  return {
    type: DELETE_WEBSITE_ERROR,
    payload: message,
  };
};

export const putRequestUpdateWebsite = (data) => {
  return {
    type: WEBSITES_UPDATE_REQUEST,
    payload: data,
  };
};
export const putRequestUpdateWebsiteSuccess = (msg) => {
  return {
    type: WEBSITES_UPDATE_SUCCESS,
    payload: msg,
  };
};
export const putRequestUpdateWebsiteError = (error) => {
  return {
    type: WEBSITES_UPDATE_ERROR,
    payload: error,
  };
};

export const gdprSuppressionUpdate = (data) => {
  return {
    type: WEBSITES_UPDATE_GDPRSUPPRESSION,
    payload: data,
  };
};

export const updateEmailDigestFrequency = (data) => {
  return {
    type: WEBSITES_UPDATE_EMAIL_DIGEST_FREQUENCY,
    payload: data,
  };
};

export const setWebsiteSettings = (data) => {
  return {
    type: SET_WEBSITE_SETTINGS,
    payload: data,
  };
};

export const addBlacklistWebsite = (data) => {
  return {
    type: ADD_BLACKLIST_WEBSITE,
    payload: data,
  };
};

export const fetchBlacklistWebsites = (id) => {
  return {
    type: FETCH_BLACKLIST_WEBSITES,
    payload: id,
  };
};

export const fetchBlacklistWebsitesSuccess = (data) => {
  return {
    type: FETCH_BLACKLIST_WEBSITES_SUCCESS,
    payload: data,
  };
};

export const fetchBlacklistWebsitesError = (error) => {
  return {
    type: FETCH_BLACKLIST_WEBSITES_ERROR,
    payload: error,
  };
};

export const RenameWorkspaceRequest = (name) => {
  return {
    type: RENAME_WORKSPACE_REQUEST,
    payload: name,
  };
};

export const RenameWorkspaceSuccess = (message) => {
  return {
    type: RENAME_WORKSPACE_SUCCESS,
    payload: message,
  };
};

export const RenameWorkspaceError = () => {
  return {
    type: RENAME_WORKSPACE_ERROR,
  };
};

export const setRenameWorkspaceModal = (mode) => {
  return {
    type: RENAME_WORKSPACE_MODAL,
    payload: mode,
  };
};

export const AddNewExcludedPagesRequest = (data) => {
  return {
    type: ADD_NEW_EXCLUDED_PAGES_REQUEST,
    payload: data,
  };
};

export const AddNewExcludedPagesSuccess = (message) => {
  return {
    type: ADD_NEW_EXCLUDED_PAGES_SUCCESS,
    payload: message,
  };
};

export const AddNewExcludedPagesError = () => {
  return {
    type: ADD_NEW_EXCLUDED_PAGES_ERROR,
  };
};

export const RedeemCodeRequest = (data) => {
  return {
    type: REDEEM_CODE_REQUEST,
    payload: data,
  };
};

export const RedeemCodeSuccess = (message) => {
  return {
    type: REDEEM_CODE_SUCCESS,
    payload: message,
  };
};

export const RedeemCodeError = () => {
  return {
    type: REDEEM_CODE_ERROR,
  };
};
