import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { ENRICH_FETCH_DATA_REQUEST, VERIFY_EMAIL_ENRICH, VERIFY_EMAIL_ENRICH_POST } from '../../constants/ActionTypes';

import {
  enrichFetchDataError,
  enrichFetchDataSuccess,
  successverifyEmailEnrich,
  clearEnrichData,
  successverifyEmailPost,
  errorverifyEmailPost,
} from '../actions/Enrich';

import { generalSuccessMessage, generalErrorMessage } from '../actions/GeneralMessage';

import { userSignOut } from '../actions/Auth';
import { setAuthorizationHeader, baseUrl, isTokenExpired } from '../../utils/session';

const fetchLeadHttpCall = async (domain, clientId, accountId, token, product) =>
  await axios
    .get(`${baseUrl}/admin/enrich?clientId=${clientId}&domain=${domain}&token=${token}&product=${product}`, {
      clientId: clientId,
      accountId: accountId,
      token,
      product,
    })
    .then((enrich) => enrich)
    .catch((error) => error);

const verifyEmailEnrichAsync = async (email, websiteId, product) =>
  await axios
    .get(`${baseUrl}/utilities/verify-email?email=${email}&websiteId=${websiteId}&product=${product}`)
    .then((lead) => lead)
    .catch((error) => error);
const verifyEmailEnrichPost = async (contact, websiteId, product) =>
  await axios
    .post(`${baseUrl}/admin/contact/verify`, { contactId: contact, websiteId, product })
    .then((lead) => lead)
    .catch((error) => error);

function* fetchLead({ payload }) {
  setAuthorizationHeader();
  const { domain, isPublicDemo, accountId, token, product } = payload;
  const clientId = isPublicDemo ? 'happierleads-demo' : 'georgeo';

  try {
    const enrich = yield call(fetchLeadHttpCall, domain, clientId, accountId, token, product);

    if (enrich.message) {
      if (enrich.response) {
        const tokenStatus = isTokenExpired(enrich.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(generalErrorMessage(enrich.response.data.error));
        yield put(enrichFetchDataError(enrich.response.data.status));
        yield put(clearEnrichData());
      } else {
        // yield put(enrichFetchDataError('Something went wrong please check your network'));

        yield put(clearEnrichData());
      }
    } else {
      yield put(enrichFetchDataSuccess(enrich.data));
    }
  } catch (error) {
    yield put(clearEnrichData());
    yield put(enrichFetchDataError(error));
  }
}

function* getRequestVerifyEmail({ payload }) {
  setAuthorizationHeader();
  const { email, websiteId, product } = payload;
  try {
    const callResponse = yield call(verifyEmailEnrichAsync, email, websiteId, product);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(enrichFetchDataError(callResponse.response.data.status));
      } else {
        yield put(enrichFetchDataError('Something went wrong please check your network'));
      }
    } else {
      yield put(
        successverifyEmailEnrich({
          status: callResponse.data.status,
          email,
        })
      );

      if (
        callResponse.data.status === 'valid' ||
        callResponse.data.status === 'catch_all' ||
        callResponse.data.status === 'catch-all' ||
        callResponse.data.status == 'accept_all'
      ) {
        yield put(generalSuccessMessage('Contact successfully verified!'));
      } else if (callResponse.data.status === 'invalid') {
        yield put(generalErrorMessage('Contact is invalid '));
      } else {
        yield put(generalErrorMessage('The status cannot be verified now'));
      }
    }
  } catch (error) {
    yield put(enrichFetchDataError(error));
  }
}
function* postRequestVerifyEmail({ payload }) {
  setAuthorizationHeader();
  const { contact, websiteId, email, product } = payload;
  try {
    const callResponse = yield call(verifyEmailEnrichPost, contact, websiteId, product);

    if (callResponse.message) {
      if (callResponse.response) {
        const tokenStatus = isTokenExpired(callResponse.response.data.status);
        if (tokenStatus) {
          yield put(userSignOut());
        }
        yield put(enrichFetchDataError(callResponse.response.data.status));
        yield put(errorverifyEmailPost());
      } else {
        yield put(errorverifyEmailPost());
      }
    } else {
      yield put(
        successverifyEmailPost({
          status: callResponse.data.verificationStatus,
          email: callResponse.data.email ?? email,
        })
      );
      if (
        callResponse.data.verificationStatus === 'valid' ||
        callResponse.data.verificationStatus === 'catch_all' ||
        callResponse.data.verificationStatus === 'catch-all' ||
        callResponse.data.verificationStatus == 'accept_all'
      ) {
        yield put(generalSuccessMessage('Contact successfully verified!'));
      } else if (callResponse.data.verificationStatus === 'invalid') {
        yield put(generalErrorMessage('Contact is invalid '));
        yield put(errorverifyEmailPost());
      } else {
        yield put(errorverifyEmailPost());
        yield put(generalErrorMessage('The status cannot be verified now'));
      }
    }
  } catch (error) {
    yield put(errorverifyEmailPost());
  }
}

export function* fetchLeadHook() {
  yield takeEvery(ENRICH_FETCH_DATA_REQUEST, fetchLead);
}

export function* enrichVerifyEmailCall() {
  yield takeEvery(VERIFY_EMAIL_ENRICH, getRequestVerifyEmail);
}

export function* enrichVerifyEmailPost() {
  yield takeEvery(VERIFY_EMAIL_ENRICH_POST, postRequestVerifyEmail);
}

export default function* rootSaga() {
  yield all([fork(fetchLeadHook), fork(enrichVerifyEmailCall), fork(enrichVerifyEmailPost)]);
}
